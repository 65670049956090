import { twMerge } from "tailwind-merge";

export const Tick = ({ className, active, invert }: { className?: string, active: boolean; invert?: boolean; }) => {
  return (
    <svg className={twMerge("transition-all duration-500", className)} style={{ width: "1em", height: "1em" }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" className={twMerge("transition-all duration-500", active ? "fill-green" : invert ? "fill-grey-mid" : "fill-white")} />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" className={twMerge("transition-all duration-500", invert ? "stroke-white" : "stroke-beige")} />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.02683 9.31686C4.78768 9.17879 4.70574 8.873 4.84381 8.63385C4.98189 8.3947 5.28768 8.31277 5.52683 8.45084L7.28964 9.4686C7.52879 9.60667 7.83459 9.52473 7.97266 9.28558L10.3154 5.22766C10.4535 4.98851 10.7593 4.90657 10.9985 5.04464C11.2376 5.18271 11.3195 5.4885 11.1815 5.72765L8.33869 10.6516C8.20062 10.8908 7.89482 10.9727 7.65567 10.8346L5.02683 9.31686Z" className={twMerge("transition-all duration-500", active ? "fill-black" : invert ? "fill-white" : "fill-beige")} />
    </svg>
  )
}